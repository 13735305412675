<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >商城管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/order' }">订单列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="margin-sm padding-sm" >

            <el-card shadow="never" style="padding:0;">
                <div class="fl-l padding-sm" style="border-right: 1px solid #ebebeb;width: 30%">
                    <div class="order-item">交易详情</div>
                    <div class="order-item">商品名称：{{name}}</div>
                    <div class="order-item ">订单编号：{{sn}}</div>
                    <div class="order-item ">支付单号：{{pay_sn}}</div>
                    <div class="order-item">交易时间：{{pay_at}}</div>
                    <div class="order-item">商品类型：
                        <span v-if="type === 1">课程</span>
                        <span v-if="type === 2">会员卡</span>
                        <span v-if="type === 3">实物商品</span>
                    </div>
                    <div class="order-item">买家：{{user_name}}</div>
                    <div class="order-item">商品总价：{{price}}</div>
                    <div class="order-item">优惠金额：{{discount_amount}}</div>
                    <div class="order-item">实付金额：{{amount}}</div>
                    <div class="order-item">支付类型：
                        <span v-if="pay_type === 1">微信支付</span>
                        <span v-if="pay_type === 2">支付宝支付</span>
                    </div>
                </div>
                <div class="fl-l padding-sm" style="width: 70%">
                    <el-steps :active="active" process-status="wait" finish-status="finish  " align-center >
                        <el-step title="创建订单" :description="created_at"></el-step>
                        <el-step title="支付订单"></el-step>
                        <el-step title="交易完成" :description="finishTime"></el-step>
                    </el-steps>

                    <div style="margin-top:20px">
                        <span v-if="status === 1">订单未支付</span>
                        <span v-if="status === 2">订单已完成</span>
                        <span v-if="status === 3">订单关闭</span>
                        <span v-if="status === 4">支付失败</span>
                    </div>
                </div>
                <div class="clear"></div>
            </el-card>

        </div>


        <div class="padding-sm margin-sm">
            <div class="padding-b-sm"></div>
            <el-card shadow="never" style="padding:0;">
                <div class="order-item">扩展信息</div>
                <div class="order-item ">收件人：{{extendUserName}}</div>
                <div class="order-item">收货地址：{{address}}</div>
                <div class="order-item">手机号：{{phone}}</div>
                <div class="order-item">邮政编码：{{postcode}}</div>
            </el-card>
        </div>


    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "detail",
        data(){
            return {
                active:1,
                amount:'',
                close_at:'',
                created_at:'',
                pay_at:'',
                pay_sn:'',
                pay_type:'',
                price:'',
                remark:'',
                type:'',
                sn:'',
                source:'',
                status:'',
                user_name:'',
                name:'',
                discount_amount:'',
                extendUserName:'',
                address:'',
                postcode:'',
                phone:'',
                finishTime:''
            }
        },
        methods:{
            ...mapActions('store',['getOrderDetail'])
        },
        mounted() {
            let id = this.$route.params.id
            this.getOrderDetail({id:id}).then(res => {
                if(res.ret === 0){
                    console.log(res)
                    this.amount = res.data.amount
                    this.close_at = res.data.close_at
                    this.created_at = res.data.created_at
                    this.pay_at = res.data.pay_at
                    this.pay_sn = res.data.pay_sn
                    this.pay_type = res.data.pay_type
                    this.type = res.data.type
                    this.price = res.data.price
                    this.remark = res.data.remark
                    this.sn = res.data.sn
                    this.source = res.data.source
                    this.user_name = res.data.user_name
                    this.name = res.data.name
                    this.discount_amount = res.data.discount_amount
                    this.extendUserName = res.data.extendUserName
                    this.address = res.data.address
                    this.postcode = res.data.postcode
                    this.phone = res.data.phone
                    this.status = res.data.status

                    this.active = res.data.status === 1 ? 1 : 3

                    if(this.status === 2){
                        this.finishTime = res.data.pay_at
                    }

                    if(this.status === 3){
                        this.finishTime = res.data.close_at
                    }

                }
            })
        }
    }
</script>

<style>
    .order-item{
        padding:5px 0
    }
    .el-step__icon{
        width: 20px;
        height: 20px;
        font-size: 12px;
    }
    .el-step__title{
        font-size: 12px;
    }
    .el-step__description.is-finish{
        color: #999;
    }

</style>